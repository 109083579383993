import React, { useEffect, useState, useRef } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import "./Final.css";
import Dropdown from "react-bootstrap/Dropdown";
import { ErrorToast, SuccessToast } from "../../Components/toast/Toast";
import axios from "axios";
import ScreenLoader from "../../Components/loader/ScreenLoader";
import { API_URL } from "../../services/client";
import downloadjs from "downloadjs";
import html2canvas from "html2canvas";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const Final = () => {
  const [userId, setUserId] = useState();
  const [userData, setUserData] = useState();
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const divRef = useRef(null);
  const { pathname } = useLocation();
  const isMobile = useMediaQuery({
    query: "(max-width: 450px)",
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const FinalData = async (user) => {
    try {
      const res = await axios.get(`${API_URL}/api/poster/${user}`);
      if (res.status === 200) {
        setUserData(res?.data?.user);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const use = localStorage.getItem("userId");
    FinalData(use);
  }, [userId]);
  useEffect(() => {
    const imageData = localStorage.getItem("imageData");
    setImage(imageData);
  }, []);

  //   const handleCaptureClick = async () => {
  //     const div = divRef.current;
  //     if (div) {
  //         const htmlContent = div.outerHTML;
  //         const response = await axios.post(`${API_URL}/api/saveImage`, { htmlContent }, {
  //             headers: {
  //                 "Content-Type": "application/json",
  //             }
  //         });

  //         // Check if the response is successful and contains the file URL
  //         if (response && response.data && response.data.success && response.data.imageUrl) {
  //           const link = document.createElement('a');
  //           link.href = response.data.imageUrl;
  //           link.target = '_blank'; // Open in a new tab/window
  //           link.setAttribute('download', ''); // Add the download attribute

  //           // Trigger a click event on the link to initiate the download
  //           link.click();
  //         } else {
  //             console.error("Failed to receive image URL from the server");
  //         }
  //     }
  // };

  const handleCaptureClick = async () => {
    // setLoader(true)
    const div = divRef.current;
    if (div) {
      try {
        const canvas = await html2canvas(div);
        const dataURL = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = dataURL;
        link.download = "yourentry.png";
        // setLoader(false)
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error generating image:", error);
        setLoader(false);
      }
    }
  };

  useEffect(() => {
    // Simulate loading for 5 seconds
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000);

    // Clear timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loading && <ScreenLoader />}
      {/* <ProgressBar now={now} /> */}

      <div className="poistion-main-fixed">
        <Navbar />
      </div>
      <div style={{ paddingTop: "70px" }}></div>
      <div
        style={{ paddingTop: "71px", minHeight: "100vh" }}
        className="step1-main"
      >
        <div className="step1-pill">
          <h1 className="main-heading">
            Thank You for <br />
            <span className="main-heading-span">
              Sharing Your Creation!
            </span>{" "}
          </h1>
        </div>

        <div className="mainTextHeader ">
          <div style={{ marginTop: "0px" }} className="text-header">
            <div className="poster-main">
              <div>
                {/* <div ref={divRef} className="test-calss pricing-table">
                  <img className="main-img" src="/Images/final.svg" />

                  <div className="inside-img">
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "20px",
                      }}
                      src={image}
                    />
                    <p className="testName-main">@legostoresme</p>
                  </div>
                  <div className="main-name-wrape">
                    <h1 className="main-name">{userData?.name}</h1>
                    <p className="main-build">
                      {" "}
                      <span className="main-build-span">Built with</span>{" "}
                      {userData?.whome?.length > 1
                        ? "My Family"
                        : userData?.whome?.map((item, index) => {
                            return (
                              <React.Fragment key={index}>
                                {item}
                                {index !== userData.whome.length - 1 &&
                                  ","}{" "}
                              </React.Fragment>
                            );
                          })}
                    </p>
                  </div>
                </div> */}
              </div>
              <div
                ref={divRef}
                style={{
                  background: "#FFC00E",
                  width: isMobile ? "100%" : "420px",
                  height: "100%",
                  borderRadius: "10px",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    textAlign: "left",
                    paddingTop: "20px",
                    paddingLeft: "20px",
                  }}
                >
                  <img src="/Images/posterLogo.svg" />
                </div>
                <div style={{ position: "absolute", top: "0%", right: "0%" }}>
                  <img src="/Images/posterhang.svg" />
                </div>
                <div
                  style={{
                    textAlign: "center",
                    paddingTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    width: isMobile ? "100%" : "420px",
                    height: "300px",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      backgroundImage: `url("/Images/preview-bg.svg")`, // Set background image here
                      backgroundSize: "contain", // Adjust as needed
                      backgroundRepeat: "no-repeat", // Adjust as needed
                      width: "240px", // Adjust as needed
                      // height: "300px",
                    }}
                  >
                    {/* <div>
                      <img
                        style={{
                          width: "240px",
                          height: "300px",
                        }}
                        src={"/Images/preview-bg.svg"}
                      />
                    </div> */}
                    <div
                      style={{ position: "absolute", top: "7%", right: "10%" }}
                    >
                      <img
                        style={{
                          width: "191px",
                          height: "191px",
                          borderRadius: "20px",
                          objectFit:"contain"
                        }}
                        src={image}
                      />

                      <p className="testName-main">@legostoresme</p>
                    </div>
                  </div>

                  <div
                    style={{ position: "absolute", right: "-3%", bottom: "0%" }}
                  >
                    <img src="/Images/child1.svg" />
                  </div>
                  <div
                    style={{ position: "absolute", left: "1%", bottom: "-17%" }}
                  >
                    <img src="/Images/child2.svg" />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    margin: "0px 3px 3px 3px",
                  }}
                >
                  <div
                    style={{
                      width: "70%",
                      background: "#0F6DB4",
                      paddingTop: "10px",
                      paddingBottom: "5px",
                      position: "relative",
                      borderRadius: "0px 0px 0px 10px",
                    }}
                  >
                    <div
                      style={{ position: "absolute", top: "-30%", left: "10%" }}
                    >
                      <img
                        style={{ width: isMobile && "100%" }}
                        src="/Images/tiles.svg"
                      />
                    </div>
                    {userData?.name && (
                      <h1 className="main-name">
                        {userData.name.length > 16
                          ? `${userData.name.slice(0, 16)}..`
                          : userData.name}
                      </h1>
                    )}
                    <p className="main-build">
                      {" "}
                      <span className="main-build-span">Built with</span>{" "}
                      {userData?.whome?.length > 1
                        ? "My Family"
                        : userData?.whome?.map((item, index) => {
                            return (
                              <React.Fragment key={index}>
                                {item}
                                {index !== userData.whome.length - 1 &&
                                  ","}{" "}
                              </React.Fragment>
                            );
                          })}
                    </p>
                  </div>

                  <div>
                    <img
                      style={{ borderRadius: "0px 0px 10px 0px" }}
                      src="/Images/poster-bottom.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="padding-top-button">
              <p className="upper-text">
                Your collaborative masterpiece is{" "}
                <span className="bold-upper">
                  now part of LEGO's Ramadan celebration.
                </span>{" "}
                <br /> Here's a{" "}
                <span className="bold-upper-y">
                  {" "}
                  preview of how your design will be showcased.
                </span>
              </p>
              <button
                onClick={() => {
                  handleCaptureClick();
                }}
                className="continue-button-active continue-button-active-2 "
              >
                Download and Share Creation
              </button>
              <img src={imageUrl} />
            </div>
            <div>
              <p className="belllow-text">
                Share your LEGO creation with the world! Use{" "}
                <span className="bold-bottom">#BuildWithEveryOne </span>
                and tag us in your posts
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};
export default Final;
