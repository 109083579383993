import "./footer.css";
const Footer = () => {
  return (
    <>
      <div class="footer-dark">
        <footer>
          <div class="">
            <div class="row-flex">
              <div class="item">
                <img src="/Images/log.svg" />
              </div>
              <div class="item item-padding">
                <h3>Discover</h3>
                <ul>
                  <li>
                    <a href="#">About LEGO</a>
                  </li>
                  <li>
                    <a href="#">LEGO Store Locator</a>
                  </li>
                  <li>
                    <a href="#">LEGO Ramadan Campaign</a>
                  </li>
                  <li>
                    <a href="#">Contact us</a>
                  </li>
                </ul>
              </div>
              <div class="item item-padding">
                <h3>Legal and Privacy</h3>
                <ul>
                  <li>
                    <a href="#">Company</a>
                  </li>
                  <li>
                    <a href="#">Team</a>
                  </li>
                  <li>
                    <a href="#">Careers</a>
                  </li>
                </ul>
              </div>
              {/* <div class=" item text">
                <h3>Company Name</h3>
                <p>
                  Praesent sed lobortis mi. Suspendisse vel placerat ligula.
                  Vivamus ac sem lacus. Ut vehicula rhoncus elementum. Etiam
                  quis tristique lectus. Aliquam in arcu eget velit pulvinar
                  dictum vel in justo.
                </p>
              </div>
              <div class="col item social">
                <a href="#">
                  <i class="icon ion-social-facebook"></i>
                </a>
                <a href="#">
                  <i class="icon ion-social-twitter"></i>
                </a>
                <a href="#">
                  <i class="icon ion-social-snapchat"></i>
                </a>
                <a href="#">
                  <i class="icon ion-social-instagram"></i>
                </a>
              </div> */}
            </div>
            <p class="copyright">
              LEGO, the LEGO logo, the Minifigure, DUPLO, FRIENDS logo, NINJAGO,
              MINIFIGURES logo are trademarks of The LEGO Group. ©2023 The LEGO
              Group COPYRIGHT ©2023 ATW Events LLC FZ. All Rights Reserved.
            </p>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
