import React from "react";
import "./legoramadan.css";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";

const LegoRamadan = () => {
  return (
    <>
      <div>
        <Navbar />
      </div>
      <div className="legoramadan__main">
        <div className="legoramadan__main-images">
          <img className="__main-image-1" src="/Images/Layer_2.svg" alt="/" />
          <img className="__main-image-2" src="/Images/Frame-1.svg" alt="/" />
          <img className="__main-image-3" src="/Images/Layer_1.svg" alt="/" />
        </div>
        <div className="legoramadan__main-hero">
          <div className="legoramadan__main-hero-txt">
            <h1>
              The challenge is over, <br /><span className="span__3">entries are now closed!</span>
            </h1>
            <p>
              On April 1st, our journey of creativity and connection has reached
              its     final brick. <span className="span__1">We're no longer accepting entries for the
              challenge.    Thank you to <span className="span__2">every builder, dreamer, and creator</span> who
              joined us in    this special celebration.</span>
            </p>
          </div>
          <div className="legoramadan__main-hero-btn">
            <img src="/Images/Frame-btn.svg" alt="/" />
            <button    onClick={ ()=>
            {window.location.href="https://www.lego.me/en-ae/c/ramadan"} } >Shop Ramadan products</button>
          </div>
          <div  className="legoramadan__social">
            <p>
              Stay tuned! The winner, whose creation will shine as a beacon of   
              our collective imagination, will be announced on April 10th. 
              Prepare     to celebrate the creativity that has brought us all
              together.
            </p>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default LegoRamadan;
