import logo from "./logo.svg";
import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import ProgressBar from "react-bootstrap/ProgressBar";
import Step1 from "./pages/step1/Step1";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Step2 from "./pages/step2/Step2";
import Step3 from "./pages/step3/Step3";
import Step4 from "./pages/step4/Step4";
import Step5 from "./pages/step5/Step5";
import Final from "./pages/final/Final";
import LegoRamadan from "./pages/legoramadan/LegoRamadan";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Term from "./pages/terms/Term";


function App() {
  const now = 20;


  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        // theme="light"
      />
      {/* <Navbar /> */}
      <BrowserRouter>
        <Routes>
          
          {/* <Route path="/" element={<Step2 />} /> */}
          {/* <Route path="/step2" element={<Step3 />} />
          <Route path="/step3" element={<Step4 />} />
          <Route path="/step4" element={<Step1 />} />
          <Route path="/step5" element={<Step5 />} />
          <Route path="/final" element={<Final />} />
          <Route path="/terms" element={<Term/>} /> */}
          <Route path="/" element={<LegoRamadan/>} />

        </Routes>
      </BrowserRouter>

      {/* <Footer /> */}
    </>
  );
}

export default App;
