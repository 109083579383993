import React, { useState } from "react";
import "./navbar.css";
import { useNavigate } from "react-router-dom";
const Navbar = () => {
  const [nav, setNav] = useState(false);
  function handleButtonClick() {
    const url = "https://lego.me"; // Replace with your desired URL
    window.open(url, "_blank");
  }
  const navigate=useNavigate()
  return (
    <>
      <div className="navbar-main">
        <div className="navbar-left">
          <img onClick={handleButtonClick} className="logo-img" src="/Images/lego-certified-stores.svg" />
          <img onClick={handleButtonClick} className="logo-img-mb" src="/Images/mobileLogo.svg" />
        </div>
        <div className="navbar-right">
          <ul className="navbar-list">
            {/* <li className="nav-list">How the Contest Works</li>
            <li className="nav-list">Rewards & Recognition</li>
            <li className="nav-list">FAQs</li>
            <li className="nav-list">Contact us</li> */}
            {/* <li onClick={()=>{navigate("/terms")}}  className="nav-list">Terms and Condition</li> */}
          </ul>
          <div className="hamburgerSign">
            <img
              onClick={() => {
                setNav(!nav);
              }}
              src="/Images/hamburger.svg"
            />
          </div>
        </div>
      </div>

      {nav && (
        <div style={{display:"flex",justifyContent:"center"}}>
          <div className="navbar-mobile">
            <ul className="navbar-mb-list">
              {/* <li onClick={()=>{navigate("/terms")}} className="nav-mb-list">Terms and Condition</li> */}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
